import React, { useEffect } from "react";
import { Footer } from "../../components/common";
import ContactUs from "../../components/common/contact-us/ContactUs";
import {
  AboutUs,
  ApplyNow,
  Calculator,
  FAQ,
  Fundings,
  Hero,
  // HomeDecorations,
  HowWeCompare,
  ImportantPoints,
  Testimonials,
  WhoWeAre,
} from "../../components/home";

const Home = ({
  headerFilename,
  headerTitle,
  headerShort,
  keyPoints,
  ourFundingsTitle,
  ourFundingsSubtitle,
  ourFundingsFilename,
  ourFundingsBubbles,
  howWeCompareTitle,
  howWeCompareShort,
  howWeCompareTable,
  calculatorTitle,
  calculatorShort,
  faqTitle,
  faqContent,
  registerTitle,
  registerShort,
  registerFormTitle,
  aboutUsTitle,
  aboutUsFeaturedIn,
  whoWeAreTitle,
  // whoWeAreStoryTitle,
  // whoWeAreStoryShort,
  // whoWeAreMissionTitle,
  // whoWeAreMissionShort,
  whoWeAreContent,
  whoWeAreFirstImage,
  whoWeAreSecondImage,
  contactUsAddress,
  contactUsPhone,
  contactUsEmail,
  twitterLink,
  instagramLink,
  tiktokLink,
  linkedInLink,
  youtubeLink,
  snapchatLink,
}) => {
  useEffect(() => {
    document.body.classList.add("home");
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    return () => {
      document.body.classList.remove("home");
    };
  }, []);

  return (
    <>
      <main>
        <Hero
          fileName={headerFilename}
          title={headerTitle}
          short={headerShort}
        />
        <ImportantPoints points={keyPoints} />
        <Fundings
          title={ourFundingsTitle}
          subtitle={ourFundingsSubtitle}
          fileName={ourFundingsFilename}
          bubbles={ourFundingsBubbles}
        />
        <AboutUs title={aboutUsTitle} featuredIn={aboutUsFeaturedIn} />
        {/* <Testimonials /> */}
        <HowWeCompare
          title={howWeCompareTitle}
          short={howWeCompareShort}
          table={howWeCompareTable}
        />
        <Calculator title={calculatorTitle} short={calculatorShort} />
        <ApplyNow
          title={registerTitle}
          short={registerShort}
          formTitle={registerFormTitle}
        />
        <FAQ title={faqTitle} content={faqContent} />
        <WhoWeAre
          title={whoWeAreTitle}
          // storyTitle={whoWeAreStoryTitle}
          // storyShort={whoWeAreStoryShort}
          // missionTitle={whoWeAreMissionTitle}
          // missionShort={whoWeAreMissionShort}
          content={whoWeAreContent}
          firstImg={whoWeAreFirstImage}
          secondImg={whoWeAreSecondImage}
        />
        <ContactUs />
      </main>
      <Footer />
      {/* <HomeDecorations /> */}
    </>
  );
};

export default Home;
