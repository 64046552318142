import React, { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Guard } from "../components";
import { Loader } from "../components/common";
import { useGetHomePageContent } from "../hooks";
import {
  Blog,
  BlogArticle,
  Dashboard,
  FundingApplication,
  FundingApplicationStepper,
  Home,
  Login,
  Verification,
} from "../pages";
import NewPasswordPage from "../pages/authorization/login/password-reset/NewPassword";
import PasswordResetPage from "../pages/authorization/login/password-reset/PasswordReset";
import PrivacyPolicyDownloadPage from "../pages/downloads/PrivacyPolicy";
import TCPAConsentDownloadPage from "../pages/downloads/TCPA";

const AppRoutes = () => {
  let { data: content, isLoading } = useGetHomePageContent();
  content = { contactUsPhone: content?.phone, ...content }; // Fix - keep variable name for phone consistent with contactUs variables

  // seems not be needed but keeping it here for reference for now
  // const location = useLocation();
  // useEffect(() => {
  //   const pagePath = location.pathname + location.search + location.hash;
  //   console.log(`sending pageview event for ${pagePath}`);
  //   TagManager.dataLayer({
  //     dataLayer: {
  //       event: "pageview",
  //       pagePath,
  //     },
  //   });
  // }, [location]);

  const routes = useMemo(() => {
    return [
      {
        key: "home",
        name: "Home",
        path: "/",
        element: isLoading ? <Loader /> : <Home {...content} />,
      },
      {
        key: "login",
        name: "Login",
        path: "/login",
        element: <Login {...content} />,
      },
      {
        key: "verification",
        name: "Verification",
        path: "/verification",
        element: (
          <Guard>
            <FundingApplicationStepper />
            <Verification {...content} />
          </Guard>
        ),
      },
      {
        key: "funding-application",
        name: "Funding Application",
        path: "/funding-application",
        element: <FundingApplication {...content} />,
      },
      {
        key: "dashboard",
        name: "Dashboard",
        path: "/dashboard",
        element: (
          <Guard>
            <Dashboard {...content} />
          </Guard>
        ),
      },
      {
        key: "tcpa-consent",
        name: "TCPA Consent",
        path: "/tcpa-consent",
        element: (
          // <Guard>
          <TCPAConsentDownloadPage />
          // </Guard>
        ),
      },
      {
        key: "privacy-policy",
        name: "Privacy Policy",
        path: "/privacy-policy",
        element: (
          // <Guard>
          <PrivacyPolicyDownloadPage />
          // </Guard>
        ),
      },
      {
        key: "reset-password",
        name: "Reset Password",
        path: "/reset-password",
        element: (
          // <Guard>
          <PasswordResetPage />
          // </Guard>
        ),
      },
      {
        key: "set-new-password",
        name: "Set New Password",
        path: "/set-new-password",
        element: (
          // <Guard>
          <NewPasswordPage />
          // </Guard>
        ),
      },
      // {
      //   key: "cookies",
      //   name: "Cookies",
      //   path: "/cookies",
      //   element: (
      //     // <Guard>
      //     <Cookies />
      //     // </Guard>
      //   ),
      // },
      {
        key: "blog",
        name: "Blog",
        path: "/blog",
        element: isLoading ? <Loader /> : <Blog />,
      },
      {
        key: "blog-article",
        name: "Blog Article",
        path: "/blog/:slug",
        element: isLoading ? <Loader /> : <BlogArticle />,
      },
    ];
  }, [content, isLoading]);

  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.key} exact path={route.path} element={route.element}>
          {route.children}
        </Route>
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
